export interface WorkspaceFeatureFlags {
  show3DMode?: boolean;
  showVideoMode?: boolean;
}

export interface UserFeatureFlags {
  showDevTools?: boolean;
  show3DMode?: boolean;
  showVideoMode?: boolean;
}

const featureFlagMapping: {
  [key: string]: keyof UserFeatureFlags | keyof WorkspaceFeatureFlags;
} = {
  show_dev_tools: "showDevTools",
  show_video_mode: "showVideoMode",
  show_3d_mode: "show3DMode",
};

export function extractFeatureFlags(
  userFeatureFlagList: string[],
  workspaceFeatureFlagList: string[],
) {
  const featureFlags: UserFeatureFlags & WorkspaceFeatureFlags = {};
  userFeatureFlagList.forEach((flag) => {
    const key = featureFlagMapping[flag];
    if (key) {
      featureFlags[key] = true;
    }
  });

  // Workspace feature flags will override any user level ones if truthy
  workspaceFeatureFlagList.forEach((flag) => {
    const key = featureFlagMapping[flag];
    if (key) {
      featureFlags[key] = true;
    }
  });

  // All features enabled in dev environment
  if (__DEV__) {
    for (const flag of Object.values(featureFlagMapping)) {
      featureFlags[flag] = true;
    }
  }

  return featureFlags;
}
