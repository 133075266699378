import { InferenceQuality } from "../../../../types";
import { DimensionsType } from "../../fields/AspectRatio/types";
import { ReferenceImageType } from "../../fields/References/types";
import { STYLE_FIELD_NAME } from "../../fields/StyleSelection/constants";
import { FormWithStyle } from "../../fields/StyleSelection/types";
import { DEFAULT_FIELDS_STYLE } from "../../fields/StyleSelection/utils";

export const FORM_FIELDS_VIDEO = {
  STYLE: STYLE_FIELD_NAME,
  TARGET_FILE: "targetFile",
  DIMENSIONS: "dimensions",
  PROMPT_TEXT: "promptText",
  PROMPT_LANGUAGE: "promptLanguage",
  PERFORMANCE: "performance",
} as const;

export interface FormTypeVideo extends FormWithStyle {
  [FORM_FIELDS_VIDEO.TARGET_FILE]: ReferenceImageType;
  [FORM_FIELDS_VIDEO.DIMENSIONS]: DimensionsType;
  [FORM_FIELDS_VIDEO.PROMPT_TEXT]?: string;
  [FORM_FIELDS_VIDEO.PROMPT_LANGUAGE]: string;
  [FORM_FIELDS_VIDEO.PERFORMANCE]: InferenceQuality;
}

export const DEFAULT_VIDEO_FORM_VALUES: FormTypeVideo = {
  ...DEFAULT_FIELDS_STYLE,
  [FORM_FIELDS_VIDEO.TARGET_FILE]: null,
  [FORM_FIELDS_VIDEO.DIMENSIONS]: [1024, 1024],
  [FORM_FIELDS_VIDEO.PROMPT_TEXT]: "",
  [FORM_FIELDS_VIDEO.PROMPT_LANGUAGE]: null,
  [FORM_FIELDS_VIDEO.PERFORMANCE]: "MEDIUM",
};
