import { styled } from "@mui/material";
import * as yup from "yup";

import { InferenceParameters } from "../../../../types";
import { getAssetURL } from "../../../../utils/assets";
import { InferenceStyleFragment } from "../../../inference/queries.graphql";
import {
  getPercentageFromWeight,
  getWeightFromPercentage,
} from "../../utils/utils";
import { STYLE_FIELD_NAME } from "./constants";
import { FormWithStyle } from "./types";

export const MIN_STYLE_WEIGHT = 0;
export const MAX_STYLE_WEIGHT = 100;

export const DEFAULT_FIELDS_STYLE = {
  [STYLE_FIELD_NAME]: null,
};

export const styleValidationSchema = yup
  .object()
  .shape({
    id: yup.string(),
    weight: yup
      .number()
      .typeError("Invalid value.")
      .min(MIN_STYLE_WEIGHT, `Weight must be at least ${MIN_STYLE_WEIGHT}.`)
      .max(MAX_STYLE_WEIGHT, `Weight must be at most ${MAX_STYLE_WEIGHT}.`),
  })
  .required("Style is required.");

export const validationSchemaWithStyle = {
  [STYLE_FIELD_NAME]: styleValidationSchema,
};

export const formatInferenceParamsForFormWithStyle = (
  params: InferenceParameters,
): Partial<FormWithStyle> => {
  const outputForm: Partial<FormWithStyle> = {};

  if (params?.styles?.length) {
    outputForm[STYLE_FIELD_NAME] = {
      id: params.styles[0].id,
      weight: getPercentageFromWeight(params.styles[0].weight),
    };
  }
  return outputForm;
};

export const formatFormWithStyleValuesForAPI = (
  inputForm: FormWithStyle,
): Partial<InferenceParameters> => {
  const parameters: Partial<InferenceParameters> = {
    styles: inputForm.style
      ? [
          {
            id: inputForm[STYLE_FIELD_NAME].id,
            weight: getWeightFromPercentage(inputForm[STYLE_FIELD_NAME].weight),
          },
        ]
      : [],
  };
  return parameters;
};

const IconImg = styled("img")({
  width: 24,
  height: 24,
});

export const getActiveStyleNameAndIcon = (
  activeStyle: InferenceStyleFragment,
) => {
  const defaultStyleThumbnail = getAssetURL("base_style.png");

  if (!activeStyle) {
    return {
      name: null,
      icon: <IconImg src={defaultStyleThumbnail} />,
    };
  } else {
    const featuredFile = activeStyle.featuredFiles?.[0]?.file;

    return {
      name: activeStyle.name,
      icon: (
        <IconImg
          src={
            featuredFile?.thumbnails?.small?.url ||
            featuredFile?.url ||
            defaultStyleThumbnail
          }
        />
      ),
    };
  }
};
