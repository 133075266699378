// Adjust if the import path or method for generating icons is different
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";

const OuterCircle = styled(Box)(({ theme }) => ({
  width: "100%",
  aspectRatio: "1/1",
  borderRadius: "50%",
  flexShrink: 0,
  ring: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.success[700],
  border: `1px solid ${theme.palette.secondary[900]}`, // Green border
}));
const AutosizedIcon = styled(FontAwesomeIcon)({
  width: "70%",
  height: "70%",
});

export const StyleAvatar = () => (
  <OuterCircle>
    <AutosizedIcon icon={solid("palette")} />
  </OuterCircle>
);
