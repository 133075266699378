import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { styled } from "@mui/system";

import { Icon } from "../Icon/Icon";

interface ImageGridProps {
  imageSrcs: string[];
  rows: number;
  columns: number;
  className?: string;
  minimizeTiles?: boolean;
}

const containerProps: PropertyKey[] = ["rows", "columns", "aspectRatio"];

const Container = styled("div", {
  shouldForwardProp: (prop) => !containerProps.includes(prop),
})<{ rows: number; columns: number; aspectRatio: string }>(
  ({ theme, rows, columns, aspectRatio }) => ({
    display: "grid",
    height: "100%",
    aspectRatio: aspectRatio,
    gridTemplateRows: rows === 1 ? "auto" : `repeat(${rows}, minmax(0, 1fr))`,
    gridTemplateColumns:
      columns === 1 ? "auto" : `repeat(${columns}, minmax(0, 1fr))`,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.secondary["700"],
  }),
);

const Placeholder = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.secondary["300"],
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: 1,
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.secondary["700"],

  "& img": {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "auto",
    height: "100%",
    objectFit: "contain",
  },
}));

const getPlaceholderCount = (
  imageSrcs: string[],
  tileCount: number,
  minimizeTiles: boolean,
) => {
  if (!minimizeTiles) {
    return tileCount - imageSrcs.length;
  } else if (imageSrcs.length === 0) {
    return 1;
  } else if (imageSrcs.length === 1) {
    return 0;
  }

  return tileCount - imageSrcs.length;
};

export const ImageGrid = (props: ImageGridProps) => {
  const { rows, columns, imageSrcs, className, minimizeTiles = true } = props;
  const tileCount = rows * columns;
  const imagesSrcsToShow = imageSrcs.slice(0, tileCount);
  const isMultipleImagesToShow = imagesSrcsToShow.length > 1;
  const shouldSimplifyGrid = !isMultipleImagesToShow && minimizeTiles;
  const finalRowCount = !shouldSimplifyGrid ? rows : 1;
  const finalColumnCount = !shouldSimplifyGrid ? columns : 1;

  const placeholderCount = getPlaceholderCount(
    imageSrcs,
    tileCount,
    minimizeTiles,
  );

  return (
    <Container
      rows={finalRowCount}
      columns={finalColumnCount}
      className={className}
      aspectRatio={`${columns} / ${rows}`}
    >
      {imagesSrcsToShow.map((imageSrc, index) => (
        <ImageContainer key={`${imageSrc}-${index}`}>
          <img alt={`Image ${index + 1}`} src={imageSrc} loading="lazy" />
        </ImageContainer>
      ))}
      {Array.from({ length: placeholderCount }).map((_, index) => (
        <Placeholder key={index}>
          <Icon size="large" icon={solid("image")} />
        </Placeholder>
      ))}
    </Container>
  );
};
