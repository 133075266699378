import * as yup from "yup";

import { validationSchemaWithStyle } from "../../fields/StyleSelection/utils";
import {
  FormTypeRealtime,
  MAX_CREATIVITY_REALTIME,
  MIN_CREATIVITY_REALTIME,
  REALTIME_FORM_FIELDS,
} from "./constants";

export const realtimeValidationSchema = yup.object().shape({
  [REALTIME_FORM_FIELDS.PROMPT_TEXT]: yup.string(),
  ...validationSchemaWithStyle,
  [REALTIME_FORM_FIELDS.CREATIVITY]: yup
    .number()
    .required()
    .min(
      MIN_CREATIVITY_REALTIME,
      `Creativity must be at least ${MIN_CREATIVITY_REALTIME}.`,
    )
    .max(
      MAX_CREATIVITY_REALTIME,
      `Creativity must be at most ${MAX_CREATIVITY_REALTIME}.`,
    ),
}) as yup.ObjectSchema<FormTypeRealtime>;
