import { UseFormReturn } from "react-hook-form";
import { createStore } from "zustand";

import { SessionInferenceMode } from "../../graphql/schema.graphql";
import { FormType2D } from "../forms/2D/types";
import { FormType3D } from "../forms/3D/constants";
import { FormTypeRealtime } from "../forms/Realtime/constants";
import { FormTypeUpscale } from "../forms/Upscale/constants";
import { FormTypeVideo } from "../forms/Video/constants";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InferenceFormProps {}

export interface InferenceFormInternalProps extends InferenceFormProps {
  activeMode: SessionInferenceMode;
  inferenceForm2D: UseFormReturn<FormType2D>;
  inferenceFormUpscale: UseFormReturn<FormTypeUpscale>;
  inferenceFormVideo: UseFormReturn<FormTypeVideo>;
  inferenceForm3D: UseFormReturn<FormType3D>;
  inferenceFormRealtime: UseFormReturn<FormTypeRealtime>;
}

export interface InferenceFormState extends InferenceFormInternalProps {
  setActiveMode: (mode: SessionInferenceMode) => void;
}

export const inferenceFormStore = (initProps: InferenceFormInternalProps) => {
  return createStore<InferenceFormState>()((set) => ({
    ...initProps,
    setActiveMode: (activeMode) => set(() => ({ activeMode })),
  }));
};

export type InferenceFormStore = ReturnType<typeof inferenceFormStore>;
