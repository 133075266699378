import * as yup from "yup";

import { validationSchemaWithStyle } from "../../fields/StyleSelection/utils";
import { FORM_FIELDS_VIDEO, FormTypeVideo } from "./constants";

export const validationSchema = yup.object().shape({
  ...validationSchemaWithStyle,
  [FORM_FIELDS_VIDEO.TARGET_FILE]: yup
    .object()
    .required("Video reference image is required."),
  [FORM_FIELDS_VIDEO.PROMPT_TEXT]: yup
    .string()
    .required("Prompt text is required."),
  // Other fields not implemented yet
}) as yup.ObjectSchema<FormTypeVideo>;
