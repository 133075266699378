import { DimensionsType } from "../fields/AspectRatio/types";

export const clip = (input: number, min: number, max: number) => {
  return Math.min(Math.max(input, min), max);
};

export const getImageObject = (imageUrl: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (err) => reject(err);
    img.src = imageUrl;
  });

export const getWeightFromPercentage = (percentage: number) => {
  return Math.round((percentage / 100) * 100) / 100;
};
export const getPercentageFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};
export const getWeightFromSimilarity = (similarity: number) => {
  return Math.round((similarity / 100) * 20) / 20;
};
export const getSimilarityFromWeight = (weight: number) => {
  return Math.round(weight * 100);
};

export const getPixelDensityFromSliderValue = (slider: number) => {
  return Math.round(Math.min(Math.max(slider, 1), 4) / 0.5) * 0.5;
};
export function getSliderValueFromPixelDensity(pixelDensity: number) {
  if (!pixelDensity) return 1;
  return Math.min(Math.max(pixelDensity, 1), 4);
}

export const getDimensionsString = (dimensions: DimensionsType) =>
  `${dimensions[0]}x${dimensions[1]}`;

// Needed to center the preview rectangle correctly in the container
export const getPreviewRectangleContainerDirection = (
  dimensions: DimensionsType,
): string => (dimensions[0] > dimensions[1] ? "row" : "column");
