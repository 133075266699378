import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Accept } from "react-dropzone";

import { getAssetURL } from "../../../../utils/assets";
import { Icon } from "../../../ui-v2";
import {
  CannyEdgeIcon,
  DepthMapIcon,
  PoseIcon,
  ReferenceImageIcon,
  StyleReferenceIcon,
} from "../../../ui-v2/icons";

export const REFERENCES_FIELD_NAME = "references";

export const MIN_REFERENCE_SIMILARITY = 0;
export const MAX_REFERENCE_SIMILARITY = 100;
export const DEFAULT_REFERENCE_SIMILARITY = 50;

export const ACCEPTED_REFERENCE_IMAGE_TYPES: Accept = {
  "image/jpeg": [".jpeg", ".jpg"],
  "image/png": [".png"],
  "image/svg": [".svg"],
  "image/webp": [".webp"],
  "image/gif": [".gif"],
};

export const REFERENCES_METADATA = {
  INIT: {
    label: "Reference Image",
    icon: <ReferenceImageIcon />,
    isDefault: true,
    incompatibleReferenceTypes: ["COLOR_SKETCH"],
    guidanceFileType: "INIT",
    preprocess: false,
    weight: 0.5,
    isLocalized: true,
    tooltip: "Use this image as a starting point and create variations.",
    tooltipImageSrc: getAssetURL("tooltip_images/variations.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
  PROMPT: {
    label: "Style Reference",
    icon: <StyleReferenceIcon />,
    isDefault: true,
    incompatibleReferenceTypes: ["FACE"],
    guidanceFileType: "PROMPT",
    preprocess: false,
    weight: 1,
    isLocalized: false,
    tooltip: "Transfer the style of an image onto your generations.",
    tooltipImageSrc: getAssetURL("tooltip_images/style_transfer.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow the style of this image, where higher percentage means more closely.",
  },
  COLOR_SKETCH: {
    label: "Color Sketch",
    icon: <Icon icon={regular("palette")} />,
    isDefault: true,
    incompatibleReferenceTypes: ["PROMPT", "CANNY", "SCRIBBLE"],
    guidanceFileType: "COLOR_SKETCH",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip: "Upload or draw a color sketch to guide your image generation.",
    tooltipImageSrc: getAssetURL("tooltip_images/sketch_color.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow this sketch, where higher percentage means more closely.",
  },
  SCRIBBLE: {
    label: "B&W Sketch",
    icon: <Icon icon={regular("scribble")} />,
    isDefault: false,
    incompatibleReferenceTypes: ["COLOR_SKETCH", "CANNY"],
    guidanceFileType: "SCRIBBLE",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Upload or draw a black and white sketch to guide your image generation.",
    tooltipImageSrc: getAssetURL("tooltip_images/sketch_b&w.jpg"),
    similarityTooltip:
      "Set how closely the generate image should follow this sketch, where higher percentage means more closely.",
  },
  FACE: {
    label: "Character Face",
    icon: <Icon icon={regular("face-laugh")} />,
    isDefault: false,
    incompatibleReferenceTypes: ["PROMPT"],
    guidanceFileType: "FACE",
    preprocess: false,
    isLocalized: false,
    weight: 1,
    tooltip:
      "Upload a close-up image of a character's face to generate images with this face.",
    tooltipImageSrc: getAssetURL("tooltip_images/character_face.jpg"),
    similarityTooltip:
      "Set how much the generate image should resemble this face, where higher percentage means higher resemblance.",
  },
  POSE: {
    label: "Character Pose",
    icon: <PoseIcon />,
    isDefault: false,
    incompatibleReferenceTypes: [],
    guidanceFileType: "POSE",
    preprocess: false,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Generate an image in a specific human pose that matches the pose of the person in your image.",
    tooltipImageSrc: getAssetURL("tooltip_images/character_pose.jpg"),
    similarityTooltip:
      "Set how closely the generate image should match this pose, where higher percentage means more closely.",
  },
  DEPTH: {
    label: "Structure",
    icon: <DepthMapIcon />,
    isDefault: false,
    incompatibleReferenceTypes: [],
    guidanceFileType: "DEPTH",
    preprocess: true,
    weight: 1,
    isLocalized: true,
    tooltip:
      "Change the appearance of your asset while maintaining its base structure.",
    tooltipImageSrc: getAssetURL("tooltip_images/depth_map.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
  CANNY: {
    label: "Canny Edge",
    icon: <CannyEdgeIcon />,
    isDefault: false,
    incompatibleReferenceTypes: ["COLOR_SKETCH", "SCRIBBLE"],
    guidanceFileType: "CANNY",
    preprocess: true,
    weight: 1,
    isLocalized: true,
    tooltip: "Generate an image following the canny edges of your image.",
    tooltipImageSrc: getAssetURL("tooltip_images/canny.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
  SEGMENTATION: {
    label: "Segmentation",
    icon: <Icon icon={regular("object-group")} />,
    isDefault: false,
    incompatibleReferenceTypes: [],
    guidanceFileType: "SEGMENTATION",
    preprocess: true,
    weight: 1,
    isLocalized: true,
    tooltip: "Generate an image keeping the same segments of your image.",
    tooltipImageSrc: getAssetURL("tooltip_images/segmentation.jpg"),
    similarityTooltip:
      "Set how similar the generated image should be to this image, where higher percentage means more similar.",
  },
} as const;
